<template>
  <div class="base-select" :class="classes">
    <div v-if="fieldLabel || $slots.default" class="base-select__label standart-title">
      <slot>{{ fieldLabel }}</slot>
      <span v-if="$attrs.required" class="base-select__label-asterisk">*</span>
      <plain-button
        v-if="hint"
        v-tooltip="{ content: hint, placement: 'top-start', container: false }"
        icon="help"
        size="small"
        color="dim"
        tabindex="-1"
        class="base-select__hint"
      />
    </div>
    <!--      :limit="10"-->
    <multiselect
      v-bind="$attrs"
      tag-placeholder="Добавить тег"
      tag-position="bottom"
      :max="maxElements"
      class="base-select__multiselect"
      @tag="addTag"
      v-on="$listeners"
    >
      <!--      @tag="addTag"-->
      <template v-if="mode === 'labelimg'" slot="singleLabel" slot-scope="props">
        <div class="base-select__single-img">
          <img :src="props.option.image" :alt="props.option.label" />
        </div>
      </template>
      <template v-if="mode === 'withimg' || mode === 'labelimg'" slot="option" slot-scope="props">
        <div class="base-select__option--img">
          <div v-if="props.option.image" class="base-select__option-img">
            <img :src="props.option.image" :alt="props.option.label" />
          </div>
          <div class="base-select__option-title">
            {{ props.option.label }}
          </div>
        </div>
      </template>
      <template #noResult>
        <span class="base-select__option-text standart-text">
          <slot name="noResult">
            {{ $t('noresult') }}
          </slot>
        </span>
      </template>
      <!--      <template #tag>-->
      <!--        <span class="base-select__option-text multiselect__tag">-->
      <!--          <slot name="tag">-->
      <!--            {{ $t('noresult') }}-->
      <!--            {{tag}}-->
      <!--          </slot>-->
      <!--        </span>-->
      <!--      </template>-->
      <!--      <template v-slot:maxElements>Your {{ maxElements }} custom text here</template>-->
      <template v-slot:maxElements>{{ $t('maximum') }}</template>
      <template #noOptions>
        <span class="base-select__option-text standart-text">
          <slot name="noResult">
            {{ $t('nooptions') }}
          </slot>
        </span>
      </template>
    </multiselect>
    <div v-if="$slots.after" class="base-select__after standart-text">
      <slot name="after" />
    </div>
  </div>
</template>

<script>
/**
 * @see https://vue-multiselect.js.org/#sub-props
 */
import Multiselect from 'vue-multiselect';
export default {
  name: 'BaseSelectWithTags',
  components: { Multiselect },
  inheritAttrs: false,
  props: {
    fieldLabel: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium'].includes(value),
    },
    mode: {
      type: String,
      default: 'default',
      validator: val => ['default', 'withimg', 'labelimg'].includes(val),
    },
    theme: {
      type: String,
      default: 'default',
      validator: value => ['default', 'plain'].includes(value),
    },
    hint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      maxElements: 10,
    };
  },
  computed: {
    classes() {
      const base = 'base-select';
      return {
        [`${base}--size_${this.size}`]: !!this.size,
        [`${base}--mode_${this.mode}`]: !!this.mode,
        [`${base}--theme_${this.theme}`]: !!this.theme,
      };
    },
  },
  watch: {
    // $attrs(val, old) {
    //   console.log(val.value.value, old.value.value);
    // },
  },
  mounted() {
    // this.$attrs.options.sort((a, b) => a.toLowerCase() - b.toLowerCase());
    this.$attrs.options.sort((a, b) => a - b);
  },
  methods: {
    addTag(newTag) {
      let addedTag = newTag.replaceAll(',', '.').replaceAll('/', '|');
      if (newTag.length > 30) {
        addedTag = addedTag.trim().slice(0, 30);
      } else addedTag = addedTag.trim();

      const tag = {
        name: addedTag,
        code: addedTag,
      };
      // console.log(newTag);
      this.$attrs.options.push(tag);
      this.$attrs.value.push(tag);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "noresult": "Элементов не найдено",
      "nooptions": "Список пуст",
      "maximum": "Количество выбранных тэгов достигло максимума."
    },
    "en": {
      "noresult": "No elements found",
      "nooptions": "List is empty"
    }
  }
</i18n>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-select {
  &__label {
    flexy(flex-start, baseline);
    margin-bottom: 10px;
    &-asterisk {
      //color: $error-color;
      color: $color-red.base;
      font-size: large;
      margin-left: 0.125rem;
    }
  }
  &__hint {
    margin-left: 0.25rem!important;
    align-self: center;
  }

  &__after {
    margin-top: 0.5rem;
  }

  // sizes
  &--size_medium {
    >>> .multiselect__single {
      padding: 7px 0 5px 8px;
    }
    >>> .multiselect__placeholder {
      margin: 0.5em 0.5rem 1em;
    }
  }

  // color themes
  &--theme_default >>> .multiselect {
    color: var(--input-color);
    &__select {
      &:before {
        color: var(--input-color);
      }
    }
    &__input {
      color: var(--input-color);
      &::placeholder {
        color: var(--input-color);
      }
    }
    &__placeholder {
      color: var(--input-color);
    }
    &__tags {
      background: transparent;
      border-color: var(--input-border);
    }
    &__content {
      &-wrapper {
        background: var(--select-bg);
        border-color: var(--input-border)!important;
      }
    }
    &--above .multiselect {
      &__content-wrapper {
        border-bottom-color: var(--input-border);
      }
    }
    &__option {
      color: var(--select-color);
      &--highlight {
        background: var(--select-highlight-bg);
        color: var(--select-highlight-color);
      }
      &--selected,
      &--selected.multiselect__option--highlight {
        background: var(--select-selected-bg);
        color: var(--select-selected-color);
      }
    }
  }
  &--theme_plain >>> .multiselect {
    color: inherit;
    &__select {
      &:before {
        color: inherit;
      }
    }
    &__input {
      color: inherit;
      &::placeholder {
        color: inherit;
      }
    }
    &__placeholder {
      color: inherit;
    }
    &__tags {
      background: transparent;
      border-color: transparent;
    }
    &__content {
      &-wrapper {
        background: var(--select-bg);
        border-color: var(--input-border)!important;
      }
    }
    &--above .multiselect {
      &__content-wrapper {
        border-bottom-color: var(--input-border);
      }
    }
    &__option {
      color: var(--select-color);
      &--highlight {
        background: var(--select-highlight-bg);
        color: var(--select-highlight-color);
      }
      &--selected,
      &--selected.multiselect__option--highlight {
        background: var(--select-selected-bg);
        color: var(--select-selected-color);
      }
    }
  }

  // base styles
  >>> .multiselect {
    font-size: 14px;

    &__select {
      transform-origin: center;
      height: unset;
      bottom: 1px;

      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        right: unset;
        margin-top: 0;
        transform: translate(-50%, -50%);
        border-color: currentColor transparent transparent;
      }
    }

    &__single {
      background: transparent;
      font-size: $font-size-base;
    }

    &__input {
      visibility: hidden;
      background: transparent;
      font-size: $font-size-base;
      padding: 7px 0 5px 8px;
      transition: color $anim-base-duration ease;

      &::placeholder {
        opacity: 0.5;
        transition: color $anim-base-duration ease;
      }
    }

    &__placeholder {
      transition: color $anim-base-duration ease;
    }

    &--active .multiselect__input {
      visibility: visible;
    }

    &__tags {
      border-radius: $border-radius-small;
      transition: border-color $anim-base-duration ease;
    }
    &__tag {
      background: $primary-color;
      color:#fff;
    }
    //&__tag-icon{cursor:pointer;margin-left:7px;position:absolute;right:0;top:0;bottom:0;font-weight:700;font-style:normal;width:22px;text-align:center;line-height:22px;-webkit-transition:all .2s ease;transition:all .2s ease;border-radius:5px}
    &__tag-icon:after {
      color:$primary-color;
    }
    &__tag-icon:focus,&__tag-icon:hover {
      background:$color-blue.light
    }
    //&__tag-icon:focus:after,&__tag-icon:hover:after{color:#fff}

    //&__tag{
    //
    //  &-icon:after {
    //    //content: "\D7";
    //    color: $primary-color;
    //    font-size: 14px;
    //  }
    //
    //}

    &__content {

      ^[0]--mode_default &,
      ^[0]--mode_withimg & {
        max-width: 100%;
      }

      &-wrapper {
        border-radius: $border-radius-small;
        border-top: 1px solid;
        margin-top: 2px;
        transition: color $anim-base-duration ease, background $anim-base-duration ease, border-color $anim-base-duration ease;
      }
    }

    &--above .multiselect {
      &__content-wrapper {
        border-bottom-width: 1px;
        margin-bottom: 2px;
        transition: border-color $anim-base-duration ease;
      }
    }

    &__option {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__option-text {
    white-space: normal;
  }

  &__option {
    &--img {
      flexy(flex-start, center);
    }
    &-img {
      flex: 0 0 auto;
      margin-right: 0.5rem;

      img {
        display: block;
      }
    }
  }
  &--mode_labelimg {
    >>> .multiselect {
      &__content-wrapper {
        width: auto;
      }
    }
  }
}
</style>
