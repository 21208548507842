<template>
  <div>
    <div class="base-resize-flavor-item">
      <div class="base-resize-flavor-item__label">
        <div class="base-resize-flavor-item__title standart-title">
          {{ title ? title : $t('title') }}
        </div>
        <div
          v-if="subtitle"
          :title="subtitle"
          class="base-resize-flavor-item__subtitle standart-text note-color"
        >
          {{ subtitle }}
        </div>
      </div>
      <div class="base-resize-flavor-item__field">
        <stack-select
          :options="list"
          :value="value"
          track-by="id"
          :custom-label="name"
          :allow-empty="false"
          :searchable="false"
          class="base-resize-flavor-item__select"
          :show-labels="false"
          @input="onChange"
        />
      </div>
    </div>
    <div class="base-resize-flavor-item">
      <div class="base-resize-flavor-item__label"></div>
      <div class="base-resize-flavor-item__field">
        <div v-if="disk" class="base-resize-flavor-item__cost standart-title">
          {{ $t('disk') + ' ' + value.disk + 'ГБ' }}
        </div>
        <div class="base-resize-flavor-item__cost standart-title">
          {{ $t('ram') + ' ' + value.ram / 1024 + 'ГБ' }}
        </div>
        <div class="base-resize-flavor-item__cost standart-title">
          {{ $t('cpu') + ' ' + value.vcpus }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StackSelect from '@/components/Select/StackSelect';
import { isNumber } from 'lodash';
export default {
  name: 'BaseResizeFlavorItem',
  components: { StackSelect },
  props: {
    title: {
      type: String,
      default: '',
    },
    disk: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
      // validator: arr =>
      //   !arr.length || arr.every(i => i.k && isNumber(parseFloat(i.cost)) && i.label),
    },
    value: {
      type: Object,
      required: true,
      // validator: obj => obj.k && isNumber(parseFloat(obj.cost)),
    },
  },
  computed: {},
  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
    name(name) {
      return this.$store.state.moduleStack.flavors
        .filter(x => x['os-flavor-access:is_public'] === true)
        .find(x => x.name === name.name)
        ? name.name
        : // : this.$t('other')
          name.name.split('-').slice(0, -1).join('-');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Продление",
    "payfor": "При оплате за",
    "ram": "Память",
    "cpu": "Процессор",
    "other": "Произвольная",
    "disk": "Диск"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.base-resize-flavor-item {
  width: 100%;

  +breakpoint(sm-and-up) {
    flexy(flex-start, center);
  }

  &__label {
    margin-bottom: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 1.5rem;
      flex: 0 0 12.5rem;
      max-width: 12.5rem;
    }
  }
  &__subtitle {
    +breakpoint(sm-and-up) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }
  }

  &__field {
    margin-top: 1rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 0;
      margin-right: 1.5rem;
      flex: 1 0 10rem;
    }
  }
  &__summary {
    +breakpoint(sm-and-up) {
      margin-right: 0;
      margin-left: auto;
      text-align: right;
    }
  }
}
</style>
