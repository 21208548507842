<template>
  <div class="resize">
    <div class="l-col">
      <BaseSelectWithTags
        v-model="selectedTags"
        :options="allTags"
        :close-on-select="false"
        :clear-on-select="false"
        :show-labels="false"
        tag-placeholder="Добавить тег"
        :hide-selected="true"
        placeholder="Найти или добавить тег"
        label="name"
        tag-position="bottom"
        :preserve-search="true"
        track-by="name"
        :multiple="true"
        :taggable="true"
      />
      <!--        open-direction="top"-->
    </div>
    <!--        @input="onChange"-->
    <!--    <div class="l-col">-->
    <!--      <label v-if="!selectedTags.length" class="standart-text">{{ $t('warning') }}</label>-->
    <!--    </div>-->
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelectWithTags from '@/components/Select/BaseSelectWithTags';
export default {
  name: 'ChangeTags',
  components: { BaseSelectWithTags },
  mixins: [setFocus],
  props: {
    name: {
      type: String,
      default: '',
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newNameServer: '',
      selectedTags: [],
    };
  },
  computed: {
    allTags() {
      const rawTags = this.$store.state.moduleStack.tags;
      return rawTags.map(x => {
        return {
          name: x,
          code: x,
        };
      });
    },
  },
  watch: {
    selectedTags(event) {
      this.$emit('change', event);
    },
  },
  mounted() {
    this.selectedTags = this.tags.map(x => {
      return {
        name: x,
        code: x,
      };
    });
  },
  methods: {},
};
</script>

<i18n>
{
  "ru": {
    "server": {
      "name": "Новое название сервера:",
      "description" : "Текстовое описание"
    },
    "warning": "Не выбрано ни одного тега. \n Вы хотите удалить теги у сервера?",
    "sure": {
      "confirm": "Хорошо"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
}
  .p-multiselect {
    width: 100%
    height: 3rem
  }

.p-highlight {
  color: #fff;
  background: #97c1fe;
}
</style>
