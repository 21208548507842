var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resize-flavor"},[_c('div',{staticClass:"l-col resize-flavor__config"},_vm._l((_vm.nav),function(opt){return _c('div',{key:opt.k,staticClass:"resize-flavor__config"},[_c('base-radio',{staticClass:"resize-flavor__config-item",attrs:{"value":opt.k,"theme":"tile"},model:{value:(_vm.flavor),callback:function ($$v) {_vm.flavor=$$v},expression:"flavor"}},[_vm._v(" "+_vm._s(opt.v)+" ")])],1)}),0),(_vm.flavor === '1')?_c('div',{staticClass:"l-col"},[_c('base-resize-flavor',{attrs:{"disk":_vm.tariff.localDisk,"list":_vm.innerList},on:{"ready":_vm.onReady,"change":_vm.onChange}})],1):_vm._e(),(_vm.flavor === '2')?_c('div',{staticClass:"l-col"},_vm._l((_vm.backupFormData),function(item){return _c('slider-stack-block',{key:item.name,staticClass:"margin-top",class:{ small: item.propwidth && item.propwidth === 'yes' },attrs:{"changable":true,"config":item.config,"label":item.label,"variable":true,"period":item.period,"period-key":item.periodKey,"cost":item.cost,"hint":item.hint},on:{"change":function($event){return _vm.onChangeSlider(item, $event)}}})}),1):_vm._e(),(_vm.summaryCost !== '0.00')?_c('page-block',{staticClass:"order-summary__list section-base"},[_c('base-collapser-resize',{attrs:{"summary":{
        more: _vm.summaryCost > 0 ? _vm.$t('summaryUp') : _vm.$t('summaryDown'),
        end:
          _vm.summaryCost > 0
            ? '+ ' + this.$n(_vm.summaryCost * _vm.period, 'currency')
            : this.$n(_vm.summaryCost * _vm.period, 'currency'),
      }},on:{"change-period":_vm.onPeriodChange}},[_c('ul',{staticClass:"order-summary__list section-base"},[_c('li',{staticClass:"order-summary__item"},[_c('div',{staticClass:"order-summary__label standart-title"},[_c('label',[_vm._v("Наименование")])]),_c('div',{staticClass:"order-summary__old standatr-text"},[_vm._v("ресурс")]),_c('div',{staticClass:"order-summary__price standatr-text"},[_vm._v(" "+_vm._s(`Цена за ${_vm.periodLabel}`)+" ")])]),_vm._l((_vm.configToCost),function(item,i){return _c('li',{key:i,staticClass:"order-summary__item"},[_c('div',{staticClass:"order-summary__label standart-title"},[_vm._v(" "+_vm._s(_vm.$t(`summaryLabels.${Object.keys(_vm.configToCost).find(x => x === i)}`))+" ")]),_c('div',{staticClass:"order-summary__old standatr-text"},[_vm._v(" "+_vm._s(item.old === item.new ? item.old : `${item.old} -> ${item.new}`)+" ")]),_c('div',{staticClass:"order-summary__price standatr-text"},[_vm._v(" "+_vm._s(_vm.$n(item.cost * (item.new - item.old) * _vm.period, 'currency'))+" ")])])})],2)])],1):_vm._e(),(_vm.isQuotaRam === 0 || _vm.isQuotaCores === 0)?_c('div',{staticClass:"l-col"},[_c('label',{staticClass:"typo__label medium-title section-base error-color"},[_vm._v(_vm._s(_vm.$t('quotas.quota')))]),(_vm.isQuotaCores === 0)?_c('div',{staticClass:"resize-flavor__quotas-first"},[_c('label',{staticClass:"resize-flavor__quotas-label"},[_vm._v(_vm._s(_vm.$t('quotas.cpulimit')))])]):_vm._e(),(_vm.isQuotaRam === 0)?_c('div',{staticClass:"resize-flavor__quotas-last"},[_c('label',{staticClass:"resize-flavor__quotas-label"},[_vm._v(_vm._s(_vm.$t('quotas.ramlimit')))])]):_vm._e()]):_vm._e(),(_vm.tariff.status !== 'shutoff')?_c('div',{staticClass:"l-col"},[_c('label',{staticClass:"typo__label medium-title section-base error-color"},[_vm._v(_vm._s(_vm.$t('text')))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }