import billMgrToolActions from '@/mixins/billmgr/billMgrToolActions';
import getLocalizedPeriod from '@/mixins/getLocalizedPeriod';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import Vue from 'vue';
import handleRedirect from '../billing/handleRedirect';
import wizard from '@/mixins/billmgr/wizard';
import storeMixin from '@/layouts/Stack/mixins/fetch';
export default {
  mixins: [wizard, billMgrToolActions, getLocalizedPeriod, wizard, handleRedirect, storeMixin],
  data() {
    return {
      config: {},
      typeFlavor: '',
      res: '',
      sp_model: null,
      sp_modal: null,
      sp_list: [],
      sp_tariff: null,
      currentFlavorId: '',
      sp_total: 0,
      startFunc: 'payment.add',
    };
  },
  computed: {
    flavors() {
      return this.$store.state.moduleStack.flavors;
    },
    servers() {
      return this.$store.state.moduleStack.servers;
    },
    getPrice() {
      return this.$store.state.moduleStack.price.length
        ? this.$store.state.moduleStack.price
        : null;
    },
  },
  mounted() {
    if (!this.getPrice) this.getStackPrice();
  },
  methods: {
    getStackPrice() {
      return this.$store
        .dispatch('moduleStack/getPrice', this.$store.getters['moduleStack/GET_CURRENT'])
        .then(() => {});
    },
    askSureOpen(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let time;
      this.$modals.open({
        name: selfName,
        text: this.$t('modal.sure.resize'),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.typeFlavor === 'base' ? this.changeFlavor() : this.createFlavor();
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    resizeFlavor(tariff) {
      // console.log(tariff);
      this.sp_tariff = tariff;
      this.$modals.open({
        name: 'ResizeFlavor',
        title: this.$t('prolong.title'),
        component: BaseLoader,
        closeOnBackdrop: false,
        props: {
          price: this.getPrice,
        },
        onOpen: inst => (this.sp_modal = inst),
        onClose: () => {
          this.sp_modal = null;
          this.sp_reset();
        },
        footer: {
          confirm: {
            props: { title: this.$t('prolong.confirm'), disabled: false },
            on: {
              click: () => {
                // this.typeFlavor === 'base' ? this.changeFlavor() : this.createFlavor();
                // this.askSure(this.typeFlavor === 'base')
                if (this.sp_tariff.status !== 'shutoff') this.askSureOpen();
                else {
                  // self.text = null;
                  // self.component = BaseLoader;
                  // self.closable = false;
                  // self.footer = false;
                  // time = Date.now();
                  this.$modals.close();
                  this.isProcessing = true;
                  this.typeFlavor === 'base' ? this.changeFlavor() : this.createFlavor();
                }
              },
            },
          },
          cancel: {
            props: { title: this.$t('prolong.order'), disabled: false },
            on: {
              click: () => this.$modals.close(),
            },
          },
        },
      });
      // const flavors = this.flavors.filter(x => x['os-flavor-access:is_public'] === true); //только базовые
      const flavors = this.flavors;
      this.sp_list = flavors.filter(x => x.id !== tariff.id);
      this.currentFlavorId = tariff.id;
      Vue.set(this.sp_modal, 'size', 'big');
      Vue.set(this.sp_modal, 'props', {
        tariff: this.sp_tariff,
        list: this.sp_list,
        value: this.$store.state.moduleStack.flavors.filter(x => x.id === tariff.id)[0],
        text: 'fields.prolong_warning.label',
      });
      Vue.set(this.sp_modal, 'on', {
        ready: () => {
          Vue.set(this.sp_modal.footer.confirm.props, 'disabled', false);
        },
        notready: () => {
          Vue.set(this.sp_modal.footer.confirm.props, 'disabled', true);
        },
        change: value => {
          this.typeFlavor = 'base';
          this.currentFlavorId = value;
        },
        create: value => {
          this.typeFlavor = 'other';
          this.config = value;
        },
        ['change-total']: value => {
          this.sp_total = value;
        },
      });
      Vue.set(this.sp_modal, 'component', this.singleProlongComponent);
    },
    sp_reset() {
      this.sp_model = null;
      this.sp_list = [];
      this.sp_tariff = null;
    },
    createFlavor() {
      const params = {
        flavor: {
          name: `uF-${this.config.cpu}-${this.config.ram * 1024}-${this.config.disk || 0}-${
            Math.random() * 100000000000000000
          }`,
          ram: this.config.ram * 1024,
          disk: this.config.disk || 0,
          vcpus: this.config.cpu,
          'os-flavor-access:is_public': false,
        },
      };
      let modal = null;
      this.$modals.open({
        name: 'CreateOtherFlavor',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.createOtherFlavor(params).then(async data => {
        this.currentFlavorId = data.flavor.id;
        await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'flavors');
        this.changeFlavor();
        this.$modals.close({ name: 'CreateOtherFlavor' });
      });
    },
    changeFlavor() {
      const params = {
        resize: {
          flavorRef: this.currentFlavorId,
        },
      };
      let modal = null;
      this.$modals.open({
        name: 'SingleProlongResult',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.sendServerAction(JSON.stringify(params))
        .then(async () => {
          this.res = await this.update();
        })
        .catch(e => console.error(e));
    },
    createOtherFlavor(params) {
      return this.$store.dispatch('moduleStack/createOtherFlavor', params);
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/setServerAction', {
        id: this.tariff.id,
        params,
      });
    },
    async update(callback) {
      this.timerId = setTimeout(() => {
        this.timerCnt = 2;
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            // console.log(data);
            if (data.status === 'ERROR' || data.status === 'ACTIVE' || data.status === 'SHUTOFF') {
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = 'data';
              if (data.status === 'ERROR') this.$modals.close({ name: 'SingleProlongResult' });
              if (data.status === 'ERROR') this.$modals.close({ name: 'ServerAction' });
              if (data.status === 'ERROR') this.$modals.close({ name: 'ResizeFlavor' });
              if (data.status === 'ERROR') this.showResModalStack('error');
            } else if (data.status !== 'VERIFY_RESIZE') {
              this.res = 'data';
              await this.update(callback);
            } else {
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = 'data';
              // this.sendServerAction(JSON.stringify({ confirmResize: null }));
              await setTimeout(
                () => this.sendServerAction(JSON.stringify({ confirmResize: null })),
                1000
              );
              console.log(this.sp_tariff);
              try {
                this.$modals.close({ name: 'SingleProlongResult' });
                this.$modals.close({ name: 'ServerAction' });
                this.$modals.close({ name: 'ResizeFlavor' });
              } catch (e) {
                console.log(e);
              }
              this.showResModalStack('success');
              const project = this.$store.getters['moduleStack/project_id'];
              await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'servers');
              await this.$store.dispatch('moduleStack/fetchQuotasServer', project);
            }
          });
      }, 1000 * this.timerCnt);
    },
    async checkStatus(callback) {
      this.timerId = setTimeout(() => {
        this.timerCnt = 2;
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (data !== 'active') {
              await this.update(callback);
            } else {
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
    },
    showResModalStack(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', this.$t(`modal.res.${res}`));
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close'), loading: false },
          on: {
            click: () => {
              this.$modals.close();
              this.$store
                .dispatch('moduleStack/updateList', { id: this.id, silent: true })
                .then(async () => {
                  await this.checkStatus();
                });
            },
          },
        },
      });
      if (this.modal.footer.confirm) {
        Vue.set(this.modal.footer, 'confirm', false);
      }
    },
  },
};
