import RescueServer from '@/layouts/Stack/components/RescueServer';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, storeMixin, modals],
  data() {
    return {
      data: {},
      params: {},
      isProcessing: false,
    };
  },
  methods: {
    rescueServer: function (instance) {
      const that = this;
      let res = '';
      let self;
      let time;
      return new Promise(() => {
        const selfName = 'RescueServer';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          title: this.$t('description.title'),
          component: RescueServer,
          closeOnBackdrop: false,
          on: {
            change: data => {
              this.params = Object.assign({}, data);
              Vue.set(self.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(self.footer.confirm.props, 'disabled', true);
            },
          },
          // onOpen: inst => (this.modal = inst),
          onOpen: inst => (self = inst),
          onClose: () => (self = null),
          onDismiss: () => (self = null),
          footer: {
            confirm: {
              props: { title: this.$t('description.rescue') },
              on: {
                click: () => {
                  Vue.set(self, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  this.sendServerAction(this.params)
                    .then(async () => {
                      await this.updateServerInfo();
                      res = 'rescue';
                      Vue.set(self, 'footer', {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close'), loading: false },
                          on: { click: () => this.$modals.close() },
                        },
                      });
                      Vue.set(self.footer, 'cancel', false);
                    })
                    // })
                    .catch(e => {
                      res = e.response.data.badRequest.message;
                      // this.showError(e.response.data.badRequest.message);
                      // console.log(e);
                      Vue.set(self, 'footer', {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close'), loading: false },
                          on: { click: () => this.$modals.close() },
                        },
                      });
                      Vue.set(self.footer, 'cancel', false);
                    })
                    .finally(() => {
                      const delay = Date.now() - time < 1000 ? 1000 : 0;
                      setTimeout(() => {
                        self.component = null;
                        self.closable = true;
                        self.text = res === 'rescue' ? this.$t(`modal.sure.${res}`) : res;
                        self.footer = {
                          centered: true,
                          confirm: {
                            props: { title: this.$t('modal.sure.close') },
                            on: { click: () => this.$modals.close() },
                          },
                        };
                      }, delay);
                      //   // if (action.delay) setTimeout(() => this.updateServerInfo(), action.delay);
                    });
                  // this.updateServer(this.params)
                  //   .then(data => {
                  //     if (data) {
                  //       that.$modals.close();
                  //       this.showResModal('Имя сервера изменено');
                  //     }
                  //   })
                  //   .catch(e => {
                  //     that.$modals.close();
                  // console.log(e.response.data.badRequest.message);
                  // this.showError(e.response.data.badRequest.message);
                  // });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    updateServer(params) {
      // console.log(params);
      return this.$store.dispatch('moduleStack/updateServer', params);
    },
    // makeModal() {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     html: true,
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal();
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
