<template>
  <div class="server-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="server-context-menu__group">
        <component
          :is="item.attrs ? (item.attrs.to ? 'router-link' : 'a') : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="server-context-menu__item standart-title"
          :class="{ [`server-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="server-context-menu__noact standart-text">
        <div v-if="isSuspended" class="standart-title">
          {{ $t('noact.suspended') }}
        </div>
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import resizeFlavor from '@/mixins/billmgr/resizeFlavor';
import changePassword from '@/mixins/changePassword';
import renameServer from '@/mixins/renameServer';
import changeTags from '@/mixins/changeTags';
import deleteServer from '@/mixins/deleteServer';
import ResizeFlavor from '../../../components/ResizeFlavor';
import storeMixin from '@/layouts/Stack/mixins';
import changeServerDescription from '@/mixins/changeServerDescription';
import rescueServer from '@/mixins/rescueServer';
import editFirewalls from '@/mixins/editFirewalls';
export default {
  name: 'ServerContextMenu',
  mixins: [
    resizeFlavor,
    storeMixin,
    changePassword,
    changeTags,
    editFirewalls,
    renameServer,
    changeServerDescription,
    rescueServer,
    deleteServer,
  ],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    localDisk: {
      type: Boolean,
      validator: value => [true, false].includes(value),
    },
  },
  data() {
    return {
      res: '',
      link: '',
      oldState: this.tariff.hourly_can,
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
      singleProlongComponent: ResizeFlavor,
    };
  },
  computed: {
    loading() {
      return this.$store.state.moduleStack.serverRequest;
    },
    isLocalDisk() {
      return this.$store.getters['moduleStack/getCurrentServer'].type === 'local';
    },
    serverState() {
      return this.tariff.status;
    },
    imageRescue() {
      const rescueImg = this.$store.state.moduleStack.images.find(x => x.tags[0] === 'rescue');
      return rescueImg && Object.keys(rescueImg).length ? rescueImg.id : null;
    },
    status() {
      return this.tariff.status.code;
    },
    id() {
      return this.tariff.id;
    },
    flavorId() {
      return this.$store.state.moduleStack.flavors.find(
        x => x.name === this.tariff.flavor.original_name
      )
        ? this.$store.state.moduleStack.flavors.find(
            x => x.name === this.tariff.flavor.original_name
          ).id
        : null;
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    menu() {
      const status = this.serverState.code;
      const arr = [];
      arr.push([
        {
          key: 'changePassword',
          handler: 'changePassword',
          // disabled: ![1, 11].includes(status),
          disabled: ![1].includes(status),
        },
        {
          key: 'rename',
          handler: 'rename',
        },
        {
          key: 'descr',
          handler: 'descr',
        },
        {
          key: 'tags',
          handler: 'tags',
        },
        // {
        //   key: 'addtag',
        //   handler: 'addtag',
        // },
        // {
        //   key: 'deltag',
        //   handler: 'deltag',
        // },
        {
          key: 'rules',
          handler: 'rules',
          action: 'rules',
        },
        {
          key: 'console',
          handler: 'console',
          disabled: ![1, 4, 44].includes(status),
        },
        {
          key: 'log',
          attrs: { to: { name: 'ServerLog', params: { serverid: this.tariff.id } } },
        },
        {
          key: this.tariff.status.code === 11 ? 'start' : 'stop',
          handler: 'action',
          action:
            this.tariff.status.code === 11
              ? {
                  'os-start': null,
                }
              : {
                  'os-stop': null,
                },
          disabled: [44].includes(status),
        },

        {
          key: this.tariff.status.code !== 17 ? 'resize' : 'confirm',
          id: this.flavorId,
          status: this.tariff.status.key,
          instanceId: this.tariff.id,
          localDisk: this.isLocalDisk || this.localDisk,
          handler: this.tariff.status.code !== 17 ? 'resize' : 'action',
          action:
            this.tariff.status.code !== 17
              ? 'flavorRef'
              : {
                  confirmResize: null,
                },
          disabled:
            this.tariff.status.code !== 17 ? ![1, 11].includes(status) : ![17].includes(status),
        },
        {
          key: 'hard',
          // text: 'text.hard',
          handler: 'action',
          action: {
            reboot: {
              type: 'HARD',
            },
          },
          disabled: ![1, 4, 5, 10, 11, 18, 19].includes(status),
        },
        {
          key: 'soft',
          // text: 'text.soft',
          handler: 'action',
          action: {
            reboot: {
              type: 'SOFT',
            },
          },
          disabled: status !== 1,
        },
        {
          key: this.tariff.status.code !== 44 ? 'rescue' : 'unrescue',
          handler: this.tariff.status.code !== 44 ? 'rescue' : 'action',
          action:
            this.tariff.status.code !== 44
              ? {
                  rescue: {
                    rescue_image_ref: this.imageRescue,
                  },
                }
              : {
                  unrescue: null,
                },
        },
        {
          key: 'delete',
          color: 'del',
          action: 'delete',
          handler: 'delete',
        },
      ]);
      return arr;
    },
  },
  mounted() {},
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    onClick(item) {
      if (item.disabled) return;
      if (item.handler === 'rescue') this.rescueServer(this.tariff);

      if (item.action && item.handler === 'action') this.askSure(item);
      if (item.handler === 'changePassword') this.changePassword(item);
      if (item.handler === 'rename') this.renameServer(this.tariff);
      if (item.handler === 'rules') this.editFirewalls(this.tariff);
      if (item.handler === 'descr') this.changeServerDescription(this.tariff);
      if (item.handler === 'tags') this.changeTags(this.tariff);
      if (item.handler === 'console') this.goToWindow(this.link);
      if (item.action && item.handler === 'delete') this.deleteServer(this.tariff);
      if (item.action && item.handler === 'resize') this.resizeFlavor(item);
    },
    askSure(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t(`modal.sure.${action.key}`, { name: this.tariff.name }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.sendServerAction(JSON.stringify(action.action))
                  .then(async () => {
                    await this.updateServerInfo();
                    res = 'success';
                  })
                  // })
                  .catch(() => (res = 'fail'))
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      self.closable = true;
                      self.text = this.$t(`modal.sure.${res}`);
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: { click: () => this.$modals.close() },
                        },
                      };
                    }, delay);
                    //   // if (action.delay) setTimeout(() => this.updateServerInfo(), action.delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else if (item.tool.type === 'group') this.sendGroupTool(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/setServerAction', {
        id: this.tariff.id,
        params,
      });
    },
    async goToWindow() {
      await this.getVncConsoles();
      return window.open(this.link, '_blank');
    },
    deleteCurrentServer() {
      return this.$store.dispatch('moduleStack/deleteServer', this.tariff.id);
    },
    async getVncConsoles() {
      const params = {
        server: this.id,
        vnc: {
          remote_console: {
            protocol: 'vnc',
            type: 'novnc',
          },
        },
      };
      return this.$store
        .dispatch('moduleStack/createVncConsole', params)
        .then(async data => {
          // return data.remote_console.url;
          this.link = data.remote_console.url;
        })
        .catch(e => this.showError(e));
    },
    async updateServerInfo(callback) {
      this.timerCnt = 1;
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            // console.log(data);
            if (data.task) {
              this.res = data.task;
              // console.log(this.res);
              await this.updateServerInfo(callback);
            } else if (['REBOOT', 'HARD_REBOOT'].includes(data.status)) {
              this.res = data.status;
              // console.log(this.res);
              await this.updateServerInfo(callback);
            } else {
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data.status;
              // console.log(this.res);
              // resolve(this.res);
              // return this.res;
              // return 'success';
            }
          });
      }, 1000 * this.timerCnt);
      return this.res;
    },
    async updateStatus() {
      this.timerCnt = 2;
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (data.task) {
              this.res = data.task;
              await this.updateStatus();
            } else if (['REBOOT', 'HARD_REBOOT'].includes(data.status)) {
              if (this.timerCnt < 5) this.timerCnt += 1;
              this.res = data.status;
              await this.updateStatus();
            } else {
              this.res = data.status;
              this.timerCnt = 0;
              this.isProcessing = false;
              this.oldState = '';
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
      return this.res;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "text": {
      "change": "изменить",
      "console": "Консоль VNC",
      "confirm": "подтвердить конфигурацию",
      "log": "Лог консоли инстанса",
      "rescue": "включить на сервере режим восстановления",
      "unrescue": "выключить на сервере режим восстановления",
      "stop": "остановить сервер",
      "descr": "Изменить описание",
      "tags": "Изменить теги",
      "gotoserver": "перейти в панель",
      "history": "история",
      "start": "запустить сервер",
      "process": "обновляем...",
      "rules": "Группы безопасности",
      "restart": "перезагрузить",
      "createBackup": "создать бэкап",
      "recreateServer": "пересоздать сервер",
      "removeServer": "удалить сервер",
      "hard": "выполнить аппаратную перезагрузку сервера",
      "soft": "выполнить программную перезагрузку сервера",
      "resize": "Изменить конфигурацию",
      "changePassword": "Сменить пароль",
      "rename": "Переименовать сервер",
      "delete": "Удалить инстанс"
    },
    "actions": {
      "change": "изменить",
      "rescue": "Режим восстановления",
      "unrescue": "Обычный режим",
      "console": "Консоль VNC",
      "descr": "Изменить описание",
      "tags": "Изменить теги",
      "stop": "Остановить",
      "rules": "Группы безопасности",
      "log": "Посмотреть лог",
      "gotoserver": "перейти в панель",
      "history": "история",
      "start": "запустить",
      "process": "обновляем...",
      "restart": "перезагрузить",
      "createBackup": "создать бэкап",
      "recreateServer": "пересоздать сервер",
      "removeServer": "удалить сервер",
      "hard": "аппаратная перезагрузка",
      "soft": "программная перезагрузка",
      "resize": "Изменить конфигурацию",
      "confirm": "Подтвердить конфигурацию",
      "changePassword": "Сменить пароль",
      "rename": "Переименовать сервер",
      "delete": "Удалить инстанс"
    },
    "prolong": {
      "confirm" : "Подтвердить",
      "order": "Отмена",
      "title": "Смена конфигурации"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить",
      "rescue": "Запустить",
      "delete": "Удалить"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь <b>%{msg}</b>. <br /><br /> Вы уверены?",
        "stop": "Остановить сервер <b>%{name}</b>?",
        "start": "Запустить сервер <b>%{name}</b>?",
        "hard": "Выполнить аппаратную перезагрузку сервера <b>%{name}</b>?",
        "soft": "Выполнить программную перезагрузку сервера <b>%{name}</b>?",
        "resize": "Для изменения указанных параметров виртуальный сервер будет перезагружен автоматически. Вы подтверждаете перезагрузку?",
        "confirm": "Подтвердить",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "rescue": "Запрос выполняется. Дождитесь статуса <<Режим восстановления>>",
        "firewalls": "Группы безопасности успешно обновлены.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    },
    "description": {
      "name": "Описание сервера:",
      "text" : "Текстовое описание",
      "title" : "Режим восстановления",
      "confirm": "Подтвердить",
      "rescue": "Запустить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),
}

.server-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: block;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.05rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.server-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
