import DeleteServer from '@/layouts/Stack/components/DeleteServer';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';

export default {
  mixins: [showErrorModal, storeMixin],
  // props: {
  //   tariff: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  data() {
    return {
      data: {},
      isProcessing: false,
    };
  },
  computed: {
    ports() {
      return this.$store.state.moduleStack.ports
        .filter(port => port.device_id === this.tariff.id)
        .map(id => id.id);
    },
  },
  methods: {
    deleteServer: function (instance) {
      const that = this;
      let res = '';
      let self;
      // const selfName = 'ServerAction';
      let time;
      return new Promise(() => {
        const selfName = 'deleteServer';
        this.$modals.open({
          name: selfName,
          size: 'medium',

          component: DeleteServer,

          closeOnBackdrop: false,
          props: {
            name: instance.name,
            instance: instance,
          },
          on: {
            change: data => {
              instance = data;
              // console.log(data);
              // console.log(this.ports);
              // Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen(inst) {
            self = inst;
          },
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.delete'), color: 'error' },
              on: {
                click: () => {
                  self.text = null;
                  self.component = BaseLoader;
                  self.closable = false;
                  self.footer = false;
                  time = Date.now();
                  this.isProcessing = true;
                  this.deleteAllPorts()
                    .then(data => console.log(data))
                    .catch(e => console.log(e));
                  this.deleteCurrentServer()
                    .then(data => {
                      if (data === 204) {
                        res = 'success';
                      } else res = 'fail';
                    })
                    .catch(() => (res = 'fail'))
                    .finally(() => {
                      const delay = Date.now() - time < 1000 ? 1000 : 0;
                      setTimeout(() => {
                        self.component = null;
                        self.closable = true;
                        self.text = this.$t(`modal.sure.${res}`);
                        self.footer = {
                          centered: true,
                          confirm: {
                            props: { title: this.$t('modal.sure.close') },
                            on: {
                              click: () => {
                                this.$modals.close();

                                this.fetchServer();
                                this.showResModal('Сервер успешно удален.');
                                this.$router
                                  .push({
                                    name: 'containerServer',
                                    query: { filter_by: 'name', value: '' },
                                  })
                                  .catch(err => {
                                    if (
                                      err.name !== 'NavigationDuplicated' &&
                                      !err.message.includes(
                                        'Avoided redundant navigation to current location'
                                      )
                                    ) {
                                      this.showErrorModal(err);
                                    }
                                  });
                              },
                            },
                          },
                        };
                      }, delay);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/setServerAction', {
        id: this.tariff.id,
        params,
      });
    },
    deleteCurrentServer() {
      return this.$store.dispatch('moduleStack/deleteServer', this.tariff.id);
    },
    async deleteCurrentPort(port) {
      return this.$store.dispatch('moduleStack/deletePort', {
        port: port,
      });
    },
    async deleteAllPorts() {
      return await this.ports.forEach(port => {
        this.deleteCurrentPort(port)
          .then(data => {
            console.log(data);
          })
          .catch(e => console.log(e));
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
